import React from "react";
import {
	getStoriesByContentType,
	getProgramListInfo,
} from "@website-builder/services";
import { useQuery, useQueries } from "react-query";
import { CourseSelector } from "@website-builder/ui/shared/components";

const UpcomingProgramsWR = ({
	header,
	context,
	programList = [],
	globalVariableWithURL,
	pageSection,
	contentType,
}) => {
	const { token } = context;
	const { data: programListPreview = [] } = useQuery(
		"storiesList",
		() => getStoriesByContentType(contentType, token && token.private),
		{
			// This api will run only in Preview Instance. In static we receive programList in props.
			enabled: programList.length === 0,
		},
	);
	/***
	 * This is to filter out the programs from the list of stories other than /program from the preview site.
	 */
	if (programListPreview.length) {
		programList = programListPreview?.filter(
			(item) => item?.full_slug?.indexOf("programs/") !== -1,
		);
	}
	const results = useQueries(getProgramListInfo(programList, contentType));
	let programs = results.filter(
		(item) => item.isFetched === true && item.status === "success",
	);
	if (programs.length >= programList.length) {
		programs = results.map((item) => {
			return item.data;
		});
	}

	const content = programs.map((item) => {
		const programSlug = programList.find(
			(programDetails) => item?.name === programDetails?.content?.programName,
		)?.slug;
		let programLink = "";
		if (process.env.GATSBY_TEMPLATE === "STATIC_SITE") {
			programLink =
				process.env.GATSBY_SITE_META_URL + "/programs/" + programSlug;
		} else {
			programLink = window.location.href + "programs/" + programSlug;
		}
		return {
			programName: item.name,
			programDescription: (
				<>
					<b>Upcoming cohort:</b> <br />
					{item?.content?.next_date_string || "Coming soon..."}
				</>
			),
			ctaLink: {
				buttonText: "View Course",
				link: {
					type: "internal",
					link: programLink,
					target: "_self",
				},
			},
		};
	});

	return (
		<CourseSelector
			showCardView={true}
			headline={header}
			content={content}
			context={context}
			pageSection={pageSection}
			globalVariableWithURL={globalVariableWithURL}
			isB2u={true}
		/>
	);
};

export default UpcomingProgramsWR;
